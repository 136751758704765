<template>
  <div v-if="menuRfpNegotiationScoringPriceApprove?.canUpdate && roleApprove && pathScoring && statusWaiting && !scoringPriceLogApproval.isNeedApprovalScoringTech">
    <div class="w-full p-4">
      <div v-if="scoringPriceLogApproval?.isAnyVendorThatHaveNotBeenQualified">
        <p class="text-sm font-normal text-gray">Price's Scores Approval</p>
        <div class="flex flex-wrap mt-1 mb-6 text-sm font-medium text-warning-dark">Unable to approve/reject due to added vendor candidate</div>
      </div>
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          type="secondary"
          color="error"
          :isDark="false"
          @click="toggle"
          :disabled="scoringPriceLogApproval?.isAnyVendorThatHaveNotBeenQualified"
          customClass="mr-6 w-1/2">
          Reject
        </ButtonGista>
        <ButtonGista 
          type="primary"
          color="success"
          @click="toggle2"
          :disabled="scoringPriceLogApproval?.isAnyVendorThatHaveNotBeenQualified"
          class="w-1/2">
          Approve</ButtonGista>
      </div>
		</div>
    <GistaModal 
      :value="isOpen"
      customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Tolak Scoring</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:300" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-56">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
    <GistaModal 
      :value="isOpen2"
      customClass="max-w-2xl bg-white"
			@input="toggle2">
			<div class="w-full p-5 text-center">
				<div class="w-full text-center mb-14">
					<h4 class="mb-8 text-3xl font-medium">Setujui?</h4>
					<p>Pastikan seluruh informasi sudah sesuai</p>
				</div>
				<div class="flex justify-center">
					<ButtonGista 
            color="success"
            customClass="w-full sm:w-56"
            @click="clickApprove"
            >
            Approve
					</ButtonGista>

				</div>
			</div>
		</GistaModal>
  </div>
</template>

<script>
import { PROJECT_SCORING } from '@/core/constant/routeName'
import { MESSAGE_SUCCESS_APPROVE_SCORING_PRICE, MESSAGE_SUCCESS_REJECT_SCORING_PRICE } from '@/core/constant/successMessage'

export default {
  name: "RfpScoringPriceApproval",
  data() {
    return {
      isOpen: false,
      isOpen2: false,
      reason: '',
    }
  },
  computed: {
    menuRfpNegotiationScoringPriceApprove() {
			return this.$store.getters['dashboard/menuRfpNegotiationScoringPriceApprove']
		},
    userProfile() {
      return this.$store.state.dashboard.userProfile
    },
    scoringPriceLogApproval() {
			return this.$store.state.negotiation.scoringPriceLogApproval
		},
    roleApprove() {
      const username = this.userProfile.username
      if (this.scoringPriceLogApproval?.isNeedApprovalItp) {
        return username === this.scoringPriceLogApproval?.itpId
      }
      if (this.scoringPriceLogApproval?.isNeedApprovalGsit) {
        return username === this.scoringPriceLogApproval?.gsitId
      }
      if (this.scoringPriceLogApproval?.isNeedApprovalUkkp) {
        return username === this.scoringPriceLogApproval?.ukkpId
      }
      return false
    },
    statusWaiting() {
      if (this.scoringPriceLogApproval?.isNeedApprovalItp) {
        return this.scoringPriceLogApproval?.itpApproved?.toLowerCase().includes('waiting')
      }
      if (this.scoringPriceLogApproval?.isNeedApprovalGsit) {
        return this.scoringPriceLogApproval?.gsitApproved?.toLowerCase().includes('waiting')
      }
      if (this.scoringPriceLogApproval?.isNeedApprovalUkkp) {
        return this.scoringPriceLogApproval?.ukkpApproved?.toLowerCase().includes('waiting')
      }
      return false
    },
    pathScoring() {
      return this.$route.path.includes(PROJECT_SCORING)
    },
    projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    toggle2() {
      this.isOpen2 = !this.isOpen2
    },
    refetchDetailBanApproval() {
      this.$store.dispatch('negotiation/refetchScoringPriceLogApproval', { rfpId: this.$route.params.projectId })
		},
    async clickApprove() {
      console.log('approve');
      try {
        this.modal.modalLoading = true
        const response = await this.$store.dispatch('negotiation/putScoringPriceApprove', { rfpId: this.$route.params.projectId })
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_APPROVE_SCORING_PRICE }
          this.modal.modalLoading = false
          this.toggle2()
          this.$store.commit('projectLog/SET_RFP_LOG_ID', this.projectLogRfp?.rfpLogs?.at(-1)?.rfpLogId)
          this.refetchDetailBanApproval()
          this.$emit('refetch')
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    },
    async clickReject() {
      console.log('reject');
      try {
        this.modal.modalLoading = true
        const response = await this.$store.dispatch('negotiation/putScoringPriceReject', { rfpId: this.$route.params.projectId, reason: this.reason })
        if (response?.status < 300 && response?.status >= 200) {
          this.reason = ''
          this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_REJECT_SCORING_PRICE }
          this.modal.modalLoading = false
          this.toggle()
          this.$store.commit('projectLog/SET_RFP_LOG_ID', this.projectLogRfp?.rfpLogs?.at(-1)?.rfpLogId)
          this.refetchDetailBanApproval()
          this.$emit('refetch')
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    }
  }
}
</script>