/* eslint-disable */
export const Store = {
  namespaced: true,
  name: 'sktLoi',
  state: {
  },
  mutations: {
  },
  actions: {
  }
  
}