/* eslint-disable */
export const Store = {
  namespaced: true,
  name: 'procurementApprovalRfq',
  state: {
    editRequester: false,
    submittedProcurementApproval: false,
    isCreateProcurementApproval: false,
    errorMaxLength: {
      'Introduction / Background & Needs': '',
      'Project Goals': '',
      'Note Internal BCA': '',
      'Exchange Rate': '',
      'Reason for Vendor Selection': ''
    },
    errorRecommendation: {
      'Recommendation': false,
      'Approver': false
    },
    items: [
      { title: 'Lorem Ipsum (2 items)', checked: true, subProduct: '', qty: 2, uom: 'pcs', qtyOrder: 2, initPrice: 5, finalPrice: 0, errorQtyOrder: '' },
      { title: 'Lorem Ipsum (2 items)', checked: true, subProduct: '', qty: 2, uom: 'ea', qtyOrder: 2, initPrice: 10, finalPrice: 10, errorQtyOrder: '' },
      { title: 'Lorem Ipsum (1 items)', checked: true, subProduct: 'Lorem Ipsum', qty: 2, uom: 'pcs', qtyOrder: 2, initPrice: 3, finalPrice: 0, errorQtyOrder: '' },
    ],
    dataCostBudgetInformation: [
      {
        posBudgetName: '',
        rcc: '',
        wbsLev3Code: '',
        wbsType: '',
        coa: '',
        category: '',
        budgetValue: '',
        error: {
          "Pos Budget Name": '',
          "RCC": '',
          "WBS Level 3 Code": '',
          "WBS Type": '',
          "COA": '',
          "Category": '',
          "Budget Value": '',
        }
      },
      {
        posBudgetName: '',
        rcc: '',
        wbsLev3Code: '',
        wbsType: '',
        coa: '',
        category: '',
        budgetValue: '',
        error: {
          "Pos Budget Name": '',
          "RCC": '',
          "WBS Level 3 Code": '',
          "WBS Type": '',
          "COA": '',
          "Category": '',
          "Budget Value": '',
        }
      },
      {
        posBudgetName: '',
        rcc: '',
        wbsLev3Code: '',
        wbsType: '',
        coa: '',
        category: '',
        budgetValue: '',
        error: {
          "Pos Budget Name": '',
          "RCC": '',
          "WBS Level 3 Code": '',
          "WBS Type": '',
          "COA": '',
          "Category": '',
          "Budget Value": '',
        }
      }
    ],
    selectedRecommendation: [{name: ''}, {name: ''}],
    selectedChecker: [{name: ''}, {name: ''}],
    selectedApprover: [{name: ''}, {name: ''}],
    selectedCandidate: [],
    vendorCandidateScoring: [
      {
        vendorSequence: 1,
        vendorName: 'apple',
        status: '',
        statusText: '',
        scoringResult: 70,
        scoringEscrow: 70,
        viewBan: true,
        currency: 'IDR',
        totalPrice: 3330000,
        waiverBg: 'Waiver Performance Bond',
        grandTotalInIdr: 3330000,
      },
      {
        vendorSequence: 2,
        vendorName: 'samsung',
        status: '',
        statusText: '',
        scoringResult: 70,
        scoringEscrow: 70,
        viewBan: true,
        currency: 'IDR',
        totalPrice: 3330000,
        waiverBg: 'Waiver Performance Bond',
        grandTotalInIdr: 3330000,
        localPartner: [
          {
            vendorSequence: 2,
            vendorName: 'samsung corp',
            status: '',
            statusText: 'Eliminasi',
            scoringResult: 70,
            scoringEscrow: 70,
            viewBan: true,
            vendorNamePrincipal: 'samsung',
            currency: 'IDR',
            totalPrice: 3330000,
            waiverBg: 'Waiver Performance Bond',
            grandTotalInIdr: 3330000,
          },
          {
            vendorSequence: 2,
            vendorName: 'pt samsung',
            status: 'Eliminasi',
            statusText: 'Eliminasi',
            scoringResult: 70,
            scoringEscrow: 70,
            viewBan: true,
            vendorNamePrincipal: 'samsung',
            nonSelect: true,
            currency: 'USD',
            totalPrice: 100,
            waiverBg: 'Waiver Performance Bond',
            grandTotalInIdr: 1443000,
          }
        ]
      },
      {
        vendorSequence: 3,
        vendorName: 'lg',
        status: '',
        statusText: '',
        scoringResult: 70,
        scoringEscrow: 70,
        viewBan: true,
        currency: 'IDR',
        totalPrice: 3330000,
        waiverBg: 'Waiver Performance Bond',
        grandTotalInIdr: 3330000,
      },
      {
        vendorSequence: 4,
        vendorName: 'asus',
        status: 'Tidak maju ke tahap negoisasi',
        statusText: 'Tidak maju ke tahap negoisasi',
        scoringResult: 70,
        scoringEscrow: 70,
        viewBan: false,
        nonSelect: true,
        currency: 'IDR',
        totalPrice: 3330000,
        waiverBg: 'Waiver Performance Bond',
        grandTotalInIdr: 3330000,
      },
      {
        vendorSequence: 5,
        vendorName: 'lenovo',
        status: 'Vendor mengundurkan diri',
        statusText: 'Vendor menungundurkan diri',
        scoringResult: '-',
        scoringEscrow: '-',
        viewBan: false,
        nonSelect: true,
        currency: 'IDR',
        totalPrice: 3330000,
        waiverBg: 'Waiver Performance Bond',
        grandTotalInIdr: 3330000,
      }
    ],
    errorVendorCandidate: []
  },
  mutations: {
    SET_EDIT_REQUESTER(state, payload) {
      state.editRequester = payload
    },
    SET_CREATE_APPROVAL(state, payload) {
      state.isCreateProcurementApproval = payload
    },
    SET_SHOW_DETAIL_APPROVAL(state, payload) {
      state.submittedProcurementApproval = payload
    },
    SET_ERROR_MAX_LENGTH(state, payload) {
      state.errorMaxLength = payload
    },
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_DATA_COST_BUDGET_INFORMATION(state, payload) {
      state.dataCostBudgetInformation = payload
    },
    SET_ERROR_RECOMMENDATION(state, payload) {
      state.errorRecommendation = payload
    },
    SET_SELECTED_CHECKER(state, payload) {
      state.selectedChecker = payload
    },
    SET_SELECTED_RECOMMENDATION(state, payload) {
      state.selectedRecommendation = payload
    },
    SET_SELECTED_APPROVER(state, payload) {
      state.selectedApprover = payload
    },
    SET_SELECTED_CANDIDATE(state, payload) {
      state.selectedCandidate = payload
    },
    SET_VENDOR_CANDIDATE_SCORING(state, payload) {
      state.vendorCandidateScoring = payload
    },
    SET_ERROR_VENDOR_CANDIDATE(state, payload) {
      state.errorVendorCandidate = payload
    },
  },
  actions: {
    async changeApprovals({commit, state}, isDone) {
      try {
        console.log(isDone, 'payload')
        if(isDone) {
          commit('SET_CREATE_APPROVAL', true)
          commit('SET_SHOW_DETAIL_APPROVAL', false)
          commit('SET_EDIT_REQUESTER', true)
        } else {
          commit('SET_SHOW_DETAIL_APPROVAL', true)
          commit('SET_EDIT_REQUESTER', false)
        }
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    checkErrorLength({ commit, state }, payload) {
      return Object.values(state.errorMaxLength).every(e => !e)
    },
    messageErrorLength({ commit, state }, payload) {
      return Object.keys(state.errorMaxLength).filter(e => state.errorMaxLength[e])
    },
    checkErrorRecommendation({ commit, state }, payload) {
      const {
        selectedRecommendation,
        selectedApprover
      } = state
      const recommendations = selectedRecommendation.filter(item => item.name !== '')
      const approver = selectedApprover.filter(item => item.name !== '')
      const filledApprover = approver.length > 0
      const filledRecommendations = recommendations.length > 0
      commit('SET_ERROR_RECOMMENDATION', {Recommendation: !filledRecommendations, Approver: !filledApprover})
      return filledRecommendations && filledApprover
    },
    checkValidationVendorCandidateScoring({ commit, state }, payload) {
      const {
        vendorCandidateScoring
      } = state
      const data = []
      let errors = 0
      vendorCandidateScoring.forEach((item, i) => {
        if(!item.localPartner) {
          const err = !item.nonSelect && item.status === ''
          data.push({err})
          if(err) errors++
        }
        else {
          data.push({err: false, local: []})
          item.localPartner.forEach(el => {
            const err = !el.nonSelect && el.status === ''
            data[i].local.push({err})
            if(err) errors++
          })
        }
      });
      commit('SET_ERROR_VENDOR_CANDIDATE', data)
      return dispatch('messageValidationVendorCandidateScoring', {errors})
    },
    messageValidationVendorCandidateScoring({ commit, state }, payload) {
      const { errors } = payload
      const { vendorCandidateScoring } = state
      if(!errors) {
        const selected = 'Terpilih'
        let mandatory = vendorCandidateScoring.filter(item => !item.nonSelect)
        vendorCandidateScoring.forEach(el => {
          if(el.localPartner) {
            const mandatoryLocal = el.localPartner.filter(item => !item.nonSelect)
            mandatory = [...mandatory, ...mandatoryLocal]
          }
        })
        const selectedVendor = mandatory.filter(item => item.status === selected)
        if(selectedVendor.length === 0) return { value: true, message: 'Silahkan periksa kembali status Vendor' }
      }
      return { value: errors > 0, message: '' }
    },
    messageErrorRecommendation({ commit, state }, payload) {
      const {errorRecommendation} = state
      return Object.keys(errorRecommendation).filter(e => errorRecommendation[e])
    },
    checkErrorQtyOrder({ commit, state }, payload) {
      return state.items.every(e => !e.errorQtyOrder)
    },
    commitErrorCostBudgetInformationSubmit({ commit, state }, payload) {
      const { dataCostBudgetInformation } = state
      const message = 'Wajib Diisi'
      const dataCommit = dataCostBudgetInformation.map(e => ({ ...e, 
        error: { ...e.error, 
          "Pos Budget Name": !e.posBudgetName? message: '',
          "RCC": !e.rcc? message: '',
          "WBS Level 3 Code": !e.wbsLev3Code? message: '',
          "WBS Type": !e.wbsType? message: '',
          "COA": !e.coa? message: '',
          "Category": !e.category? message: '',
          "Budget Value": !e.budgetValue? message: '' } 
        }))
      commit('SET_DATA_COST_BUDGET_INFORMATION', dataCommit)
      return dataCommit
    },
    commitErrorCostBudgetInformationSaveAsDraft({ commit, state }, payload) {
      const { dataCostBudgetInformation } = state
      const map = {
        "Pos Budget Name": { lengthMax: 200, message: 'Maksimal 200 karakter' },
        "RCC": { lengthMax: 10, message: 'Maksimal 10 digit' },
        "WBS Level 3 Code": { lengthMax: 100, message: 'Maksimal 100 karakter' },
        "COA": { lengthMax: 20, message: 'Maksimal 20 karakter'},
        "Budget Value": { lengthMax: 14, message: 'Maksimal 14 digit' },
      }
      const dataCommit = dataCostBudgetInformation.map(e => ({ ...e, 
        error: { ...e.error, 
          "Pos Budget Name": e.posBudgetName.length > map['Pos Budget Name'].lengthMax && e.posBudgetName ? map['Pos Budget Name'].message: '',
          "RCC": e.rcc.length > map['RCC'].lengthMax && e.rcc ? map['RCC'].message: '',
          "WBS Level 3 Code": e.wbsLev3Code.length > map['WBS Level 3 Code'].lengthMax && e.wbsLev3Code ? map['WBS Level 3 Code'].message: '',
          "WBS Type": '',
          "COA": e.coa.length > map['COA'].lengthMax && e.coa ? map['COA'].message: '',
          "Category": '',
          "Budget Value": e.budgetValue.length > map['Budget Value'].lengthMax && e.budgetValue ? map['Budget Value'].message: '' } 
        }))
      commit('SET_DATA_COST_BUDGET_INFORMATION', dataCommit)
      return dataCommit
    },
    async checkErrorCostBudgetInformation({ commit, state, dispatch }, payload) {
      const { isSubmit } = payload
      let result = []
      if (isSubmit) {
        result = await dispatch('commitErrorCostBudgetInformationSubmit')
      } else {
        result = await dispatch('commitErrorCostBudgetInformationSaveAsDraft')
      }
      return result.every(e => Object.values(e.error).every(el => !el))
    }
  }
  
}