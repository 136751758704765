const RFP = () => import("@/modules/module-rfp/pages/RFP");

const ProjectDetails = () => import("@/modules/module-rfp/pages/ProjectDetails");

const AddVendorCandidate = () => import("@/modules/module-rfp/pages/AddVendorCandidate");

import { EDITRFP, RFPNAME, DRAFTRFP, DETAIL, PRAQUALIFICATION, QUALIFICATION, SCORING, NEGOTIATION, ADDVENDORCANDIDATE,
  PATH_RFP, PATH_DRAFT_RFP, PATH_PROJECT_DETAIL, PATH_PROJECT_PRA_QUALIFICATION, PATH_PROJECT_QUALIFICATION, PATH_PROJECT_SCORING, 
  PATH_PROJECT_NEGOTIATION, PATH_ADD_VENDOR_CANDIDATE, PATH_EDIT_RFP
 } from '@/core/constant/routeName'

export const Routes = [
  {
    path: PATH_RFP,
    name: RFPNAME,
    component: RFP,
  },
  {
    path: PATH_DRAFT_RFP,
    name: DRAFTRFP,
    component: RFP,
  },
	{
		path: PATH_PROJECT_DETAIL,
		name: DETAIL,
		component: ProjectDetails,
	},
  {
    path: PATH_PROJECT_PRA_QUALIFICATION,
    name: PRAQUALIFICATION,
    component: ProjectDetails,
  },
  {
    path: PATH_PROJECT_QUALIFICATION,
    name: QUALIFICATION,
    component: ProjectDetails,
  },
  {
    path: PATH_PROJECT_SCORING,
    name: SCORING,
    component: ProjectDetails,
  },
  {
    path: PATH_PROJECT_NEGOTIATION,
    name: NEGOTIATION,
    component: ProjectDetails,
  },
  {
    path: PATH_ADD_VENDOR_CANDIDATE,
    name: ADDVENDORCANDIDATE,
    component: AddVendorCandidate,
  },
  // {
  //   path: PATH_PROJECT_APPROVAL,
  //   name: APPROVAL,
  //   component: ProjectDetails,
  // },
  // {
  //   path: PATH_PROJECT_SKT_LOI,
  //   name: SKTLOI,
  //   component: ProjectDetails,
  // },
  {
    path: PATH_EDIT_RFP,
    name: EDITRFP,
    component: RFP,
  },
];
