<template>
	<div v-if="false" class="w-full">
		<div class="w-full p-4">
      <p class="text-sm font-normal text-gray">SKT & LOI Approval</p>
      <div class="flex flex-wrap mt-1 mb-6 text-sm font-medium text-gray-dark">Please review the SKT and LOI of each vendor</div>
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          type="secondary"
          color="error"
          :isDark="false"
          @click="toggle"
          customClass="w-full">
          Reject
        </ButtonGista>
        <ButtonGista 
          type="primary"
          color="success"
          @click="toggleApprove"
          class="w-full">
          Approve</ButtonGista>
      </div>
		</div>
		<GistaModal 
      :value="isOpen"
      customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Tolak SKT</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:300" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-60">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
		<GistaModal 
      :value="isOpen2"
      customClass="max-w-2xl bg-white"
			@input="toggleApprove">
			<div class="w-full p-5 text-center">
				<div class="w-full text-center mb-14">
					<h4 class="mb-8 text-3xl font-medium">Setujui?</h4>
					<p>Pastikan seluruh informasi sudah sesuai</p>
				</div>
				<div class="flex justify-center">
					<ButtonGista 
            color="success"
            customClass="w-full sm:w-56"
            @click="clickApprove"
            >
            Approve
					</ButtonGista>

				</div>
			</div>
		</GistaModal>
	</div>
</template>
<script>
import { MESSAGE_SUCCESS_APPROVE_SKT_LOI, MESSAGE_SUCCESS_REJECT_SKT_LOI } from '@/core/constant/successMessage'
export default {
	name: "SktLoiApproval",
	data() {
		return {
			isOpen: false,
			isOpen2: false,
      reason: ''
		}
	},
  props: ['roleGsitUkkp'],
  computed: {
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
  },
	methods: {
    async clickReject() {
      this.isOpen = false
      try {
				this.modal.modalLoading = true
				//reset state reason
				this.reason = ''
				
				this.modal.modalLoading = false
				this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_REJECT_SKT_LOI }
			} catch (error) {
				console.log(error);
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
    },
    async clickApprove() {
      this.isOpen2 = false
      try {
				//show loading
				this.modal.modalLoading = true
        
				this.modal.modalLoading = false
				this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_APPROVE_SKT_LOI }
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
    },
		toggle() {
			this.isOpen = !this.isOpen
      this.reason = ''
		},
		toggleApprove() {
			this.isOpen2 = !this.isOpen2
		},
	},
}
</script>

<style scoped>
.width-button-submit {
  max-width: 40rem;
}
</style>