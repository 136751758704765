export const formatCurrency = (number, currency, showCurrency) => {
  if (!currency) currency = 'idr'
  const currencyLower = currency.toLowerCase()
  const mapCurrency = {
    idr: { locale: 'id-ID', currency: 'IDR' },
    usd: { locale: 'en-US', currency: 'USD' },
    sgd: { locale: 'en-SG', currency: 'SGD' },
    hkd: { locale: 'en-HK', currency: 'HKD' },
  }
  let selected = mapCurrency[currencyLower]
  if (!selected) selected = mapCurrency['idr']
  const int = new Intl.NumberFormat(selected.locale, {
    style: "currency",
    currency: selected.currency
  }).formatToParts(number);
  let value = ''
  int.forEach(e => {
    if(e.type !== "literal" && e.type !== "currency") value += e.value
  })
  if(showCurrency) return `${selected.currency} ${value}`
  return value
}

export const formatRupiah = (number) => {
  if (!number) return 'Rp 0'
  return new Intl.NumberFormat('id-ID', {
    style: "currency",
    currency: 'IDR'
  }).format(number);
}

export const formatThousandSeparate = (string, currencyParam) => {
  if (!string) return ''
  const currency = currencyParam || 'usd'
  const currencyLower = currency.toLowerCase()
  const mapCurrency = {
    idr: { locale: 'id-ID', currency: 'IDR' },
    usd: { locale: 'en-US', currency: 'USD' },
    sgd: { locale: 'en-SG', currency: 'SGD' },
    hkd: { locale: 'en-HK', currency: 'HKD' },
  }
  return new Intl.NumberFormat(mapCurrency[currencyLower].locale).format(string);
}
