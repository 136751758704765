//constant for modal success message

export const MESSAGE_SUCCESS_SAVE_AS_DRAFT = 'Berhasil Save As draft'
export const MESSAGE_SUCCESS_SUBMIT = 'Berhasil Submit'

export const MESSAGE_SUCCESS_ADD_VENDOR_CANDIDATE = 'Berhasil Edit Vendor Candidate'

//list project
export const MESSAGE_SUCCESS_DELETE_DRAFT = 'Success Delete Draft'

//project log approve
export const MESSAGE_SUCCESS_APPROVE_RFP = 'Berhasil Approve'
export const MESSAGE_SUCCESS_APPROVE_RFQ = 'Berhasil Approve'
export const MESSAGE_SUCCESS_APPROVE_SCORING = 'Scoring Technical & Escrow telah disetujui'
export const MESSAGE_SUCCESS_REJECT_SCORING = 'Scoring Technical & Escrow telah ditolak'
export const MESSAGE_SUCCESS_REJECT_RFP = 'Reject Note RFP Telah Dikirim'
export const MESSAGE_SUCCESS_REJECT_RFQ = 'Reject Note RFQ Telah Dikirim'
export const MESSAGE_SUCCESS_CANCEL_RFP = 'RFP Berhasil Dicancel'
export const MESSAGE_SUCCESS_CANCEL_RFQ = 'RFQ Berhasil Dicancel'
export const MESSAGE_SUCCESS_CHANGE_APPROVER = 'Berhasil Change Approver'
export const MESSAGE_SUCCESS_APPROVE_SCORING_PRICE = 'Berhasil Approve'
export const MESSAGE_SUCCESS_REJECT_SCORING_PRICE = 'Reject Note Telah Dikirim'


//qualification
export const MESSAGE_SUCCESS_ELIMINATE = 'Vendor telah dieliminasi'
export const MESSAGE_SUCCESS_APPROVE_PROPOSAL = 'Proposal Telah Disetujui'
export const MESSAGE_SUCCESS_REJECT_PROPOSAL = 'Permintaan Reject Telah Dikirimkan ke Staff ITP'
export const MESSAGE_SUCCESS_ASK_FOR_CHANGES = 'Berhasil Send Email Proposal Rejection'
export const MESSAGE_SUCCESS_SEND_PROPOSAL = 'Proposal Telah Dikirim Ke Technical Coordinator'
export const MESSAGE_SUCCESS_SEND_EMAIL = 'Email Telah Di Kirim Ke Vendor'
export const MESSAGE_SUCCESS_SEND_RFP_CONFIRMATION = 'RFP telah dikirim ke vendor'



//scoring
export const MESSAGE_SUCCESS_EDIT_SCORING_CRITERIA = 'Berhasil Edit Scoring Criteria'
export const MESSAGE_SUCCESS_SCORING_DECISION = 'Berhasil Scoring Decision'
export const MESSAGE_SUCCESS_SCORING_ESCROW = 'Berhasil Edit Scoring Escrow'
export const MESSAGE_SUCCESS_SCORING_VENDOR = 'Berhasil Edit Scoring Vendor'
export const MESSAGE_SUCCESS_SCORING_REMARKS = 'Berhasil Edit Remarks'


//negotiation
export const MESSAGE_SUCCESS_SCORING_SUBMIT = 'Scoring Price telah berhasil di submit'
export const MESSAGE_SUCCESS_CHANGE_VERIFICATOR = 'Verificator berhasil diubah'
export const MESSAGE_SUCCESS_SUBMIT_BAN = 'BAN telah di submit untuk di verifikasi'
export const MESSAGE_SUCCESS_SUBMIT_FPTN = 'FPTN telah di submit untuk di verifikasi'


//rfi
export const MESSAGE_SUCCESS_SEND_RFI = 'RFI telah dikirim ke vendor'



//rfq
export const MESSAGE_SUCCESS_SEND_RFQ = 'RFQ telah dikirim ke vendor'


//procurement approval
export const MESSAGE_SUCCESS_CREATE_APPROVAL = 'Procurement Approval telah di submit untuk approval'
export const MESSAGE_SUCCESS_APPROVAL_CHANGE_APPROVER = 'Approvers telah diubah'
export const MESSAGE_SUCCESS_REJECT_APPROVAL = 'Reject Reason Approval telah dikirim'
export const MESSAGE_SUCCESS_UPLOAD_APPROVAL = 'Approval berhasil diunggah dan disetujui oleh Approver'
export const MESSAGE_SUCCESS_CHANGE_REQUESTER = 'Requester telah diubah'

// skt & loi
export const MESSAGE_SUCCESS_CREATE_SKT = 'SKT telah di submit untuk approval'
export const MESSAGE_SUCCESS_CREATE_SKT_LOI = 'SKT dan LOI telah di submit untuk approval'
export const MESSAGE_SUCCESS_REJECT_SKT_LOI = 'Reject Reason SKT telah dikirim'
export const MESSAGE_SUCCESS_APPROVE_SKT_LOI = 'Berhasil Approve'

// config master
export const MESSAGE_SUCCESS_SAVE_OFFICIAL = 'Pejabat telah ditambahkan'
export const MESSAGE_SUCCESS_DELETE_OFFICIAL = 'Pejabat telah dihapus'
export const MESSAGE_SUCCESS_RESTORE_OFFICIAL = 'Status pejabat telah diaktifkan kembali'
export const MESSAGE_SUCCESS_ADD_MAPPING_RCC = 'Mapping RCC telah ditambahkan'
export const MESSAGE_SUCCESS_EDIT_MAPPING_RCC = 'Mapping RCC telah diubah'
export const MESSAGE_SUCCESS_DELETE_MAPPING_RCC = 'Mapping RCC telah dihapus'