//konstanta ENDPOINT Id
export const ENDPOINT_REPORT_CHART                                = 'dashboard/report/chart'
export const ENDPOINT_REPORT_DOWNLOAD                             = 'dashboard/report/download'


export const ENDPOINT_DASHBOARD                                   = 'dashboard/menu'

export const ENDPOINT_USER                                        = 'user'
export const ENDPOINT_USER_APPROVER                               = 'user/approver'
export const ENDPOINT_USER_APPROVER_V2                            = 'user/approver/v2'
export const ENDPOINT_DASHBOARD_RFX                               = 'dashboard/rfx'
export const ENDPOINT_DASHBOARD_RFX_CHECK                         = 'dashboard/rfx/check'


export const ENDPOINT_RFP                                         = 'rfp'
export const ENDPOINT_RFP_GET_MGR                                 = 'rfp/get-mgr'
export const ENDPOINT_RFP_WBS                                     = 'rfp/wbs'
export const ENDPOINT_RFP_VENDOR                                  = 'rfp/vendor'
export const ENDOPINT_RFP_SCORING_TEKNIS_CRITERIA                 = 'rfp/scoring_teknis_criteria'
export const ENDPOINT_RFP_ACTION_SUBMIT                           = 'rfp/submit'
export const ENDPOINT_RFP_PROPOSAL_INFORMATION                    = 'rfp/proposal_information'
export const ENDPOINT_DETAILS_REJECT                              = 'rfp/proposal_information/reject'
export const ENDPOINT_DETAILS_APPROVE                             = 'rfp/proposal_information/approve'
export const ENDPOINT_DETAILS_CANCEL                              = 'rfp/proposal_information/cancel'
export const ENDOPINT_PROJECT_INFORMATION                         = 'rfp/project_information'
export const ENDPOINT_PROJECT_REQUIREMENT                         = 'rfp/project_requirement'
export const ENDPOINT_PROJECT_SCORING_INFORMATION                 = 'rfp/project_scoring_information'
export const ENDPOINT_RFP_DETAILS_VENDOR_CANDIDATE                = 'rfp/project_vendor_candidate'
export const ENDPOINT_DETAILS_UPDATE_APPROVER                     = 'rfp/change_approver'
export const ENDPOINT_RFP_DELETE                                  = 'rfp/delete'
export const ENDPOINT_RFP_LOG                                     = 'rfp/log'
export const ENDPOINT_RFP_LOG_APPROVAL                            = 'rfp/log/approval'

export const ENDPOINT_QUALIFICATION                               = 'rfp/qualifications'
export const ENDPOINT_QUALIFICATION_ACTION_DISQUALIFIED           = 'rfp/qualifications/eliminate_vendor'
export const ENDPOINT_QUALIFICATION_ACTION_APPROVE                = 'rfp/qualifications/popup/proposal_approval'
export const ENDPOINT_QUALIFICATION_ACTION_REVISION               = 'rfp/qualifications/popup/proposal_revision_tech'
export const ENDPOINT_QUALIFICATION_ACTION_ASK_REVISION           = 'rfp/qualifications/popup/proposal_revision'
export const ENDPOINT_QUALIFICATION_ACTION_SEND_RFP               = 'rfp/qualifications/send_rfp'
export const ENDPOINT_QUALIFICATION_ACTION_SEND_PROPOSAL          = 'rfp/proposal/send'

export const ENDPOINT_SCORING_TEKNIS                              = 'rfp/scoring_vendor_teknis'
export const ENDPOINT_SCORING_VENDOR_TEKNIS_TREE                  = 'rfp/scoring_vendor_teknis_tree'
export const ENDPOINT_SCORING_ESCROW                              = 'rfp/scoring_vendor_escrow'
export const ENDPOINT_SCORING_ESCROW_RISK_FACTOR                  = 'rfp/scoring_escrow_risk_factor'
export const ENDPOINT_SCORING_ESCROW_RISK_FACTOR_POINT            = 'rfp/scoring_escrow_risk_factor_point'
export const ENDPOINT_SCORING_DECISION                            = 'rfp/scoring_vendor_decision'
export const ENDPOINT_SCORING_ATTACHMENTS                         = 'rfp/scoring_attachments'
export const ENDPOINT_SCORING_VENDOR_APPROVAL                     = 'rfp/scoring_vendor/approval'
export const ENDPOINT_RFP_SCORING_APPROVE                         = 'rfp/scoring_vendor/approve' 
export const ENDPOINT_RFP_SCORING_REJECT                          = 'rfp/scoring_vendor/reject' 
export const ENDPOINT_RFP_SCORING_CHANGE_APPROVAL                 = 'rfp/scoring_vendor/change_approval'
export const ENDPOINT_RFP_SCORING_VENDOR_REVIEW                   = 'rfp/scoring_vendor/review'

export const ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE               = 'rfp/scoring/teknis/price' 
export const ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_APPROVE       = 'rfp/scoring/teknis/price/approve' 
export const ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_REJECT        = 'rfp/scoring/teknis/price/reject'

export const ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_LOG_APPROVAL  = 'rfp/scoring/teknis/price/log/approval' 
export const ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_SUBMIT        = 'rfp/scoring/teknis/price/submit' 
export const ENDPOINT_RFP_SCORING_TEKNIS_PRICE_CRITERIA           = 'rfp/scoring/teknis/price/criteria'

export const ENDPOINT_RFP_NEGOTIATION                             = 'rfp/negotiation' 
export const ENDPOINT_RFP_NEGOTIATION_DETAILS                     = 'rfp/negotiation/details' 
export const ENDPOINT_RFP_NEGOTIATION_DETAILS_BAN                 = 'rfp/negotiation/details/ban'
export const ENDPOINT_RFP_NEGOTIATION_EXPORT_MENU                 = 'rfp/negotiation/details/export/menu' 
export const ENDPOINT_RFP_NEGOTIATION_EXPORT_XLSX                 = 'rfp/negotiation/details/export/xlsx' 
export const ENDPOINT_RFP_NEGOTIATION_OFFER_DELETE                = 'rfp/negotiation/details/offer/delete' 
export const ENDPOINT_RFP_NEGOTIATION_OFFER_LIST                  = 'rfp/negotiation/details/offer/list' 
export const ENDPOINT_RFP_NEGOTIATION_OFFER_REVISION              = 'rfp/negotiation/details/offer/revision' 
export const ENDPOINT_RFP_NEGOTIATION_DETAILS_OFFER_REVISION_SUBMIT = 'rfp/negotiation/details/offer/revision/submit'
export const ENDPOINT_RFP_NEGOTIATION_UPLOAD_OFFER                = 'rfp/negotiation/details/offer/upload' 
export const ENDPOINT_RFP_NEGOTIATION_SCHEDULE_CONFIRM            = 'rfp/negotiation/details/schedule/confirm' 
export const ENDPOINT_RFP_NEGOTIATION_OFFER_GET                   = 'rfp/negotiation/offer/get' 
export const ENDPOINT_RFP_NEGOTIATION_OFFER_LAST                  = 'rfp/negotiation/offer/last'  
export const ENDPOINT_RFP_NEGOTIATION_OFFER_SAVE                  = 'rfp/negotiation/offer/save' 
export const ENDPOINT_RFP_NEGOTIATION_SUMMARY_WITH                = 'rfp/negotiation/summary-with'  
export const ENDPOINT_RFP_NEGOTIATION_BAN                         = 'rfp/negotiation/ban' 
export const ENDPOINT_RFP_NEGOTIATION_BAN_APPROVE                 = 'rfp/negotiation/ban/approve' 
export const ENDPOINT_RFP_NEGOTIATION_BAN_VERIFICATOR_CHANGE      = 'rfp/negotiation/ban/change-verificator'
export const ENDPOINT_RFP_NEGOTIATION_BAN_PREVIEW                 = 'rfp/negotiation/ban/preview' 
export const ENDPOINT_RFP_NEGOTIATION_BAN_REJECT                  = 'rfp/negotiation/ban/reject' 
export const ENDPOINT_RFP_NEGOTIATION_BAN_REPORT                  = 'rfp/negotiation/ban/report' 
export const ENDPOINT_RFP_NEGOTIATION_BAN_SUBMIT                  = 'rfp/negotiation/ban/submit' 
export const ENDPOINT_RFP_NEGOTIATION_RENEGOTIATION               = 'rfp/negotiation/renegotiation' 
export const ENDPOINT_RFP_NEGOTIATION_DETAILS_BAN_APPROVER        = 'rfp/negotiation/details/ban/approver'

export const ENDPOINT_MASTERDATA_TAX                              = 'masterdata/tax'
export const ENDPOINT_USER_APPROVER_NEGOTIATION                   = 'user/approver/negotiation'

export const ENDPOINT_RFQ                                         = 'rfq'
export const ENDPOINT_RFQ_DETAILS                                 = 'rfq/quotation_information'  
export const ENDPOINT_RFQ_DETAILS_APPROVE                         = 'rfq/quotation_information/approve'  
export const ENDPOINT_RFQ_DETAILS_CANCEL                          = 'rfq/quotation_information/cancel'  
export const ENDPOINT_RFQ_DETAILS_UPDATE_APPROVER                 = 'rfq/quotation_information/change_approver'  
export const ENDPOINT_RFQ_DETAILS_REJECT                          = 'rfq/quotation_information/reject'  
export const ENDPOINT_RFQ_DETAILS_SEND_RFQ                        = 'rfq/quotation_information/send_rfq'  
export const ENDPOINT_RFQ_ACTION_SUBMIT                           = 'rfq/submit'  
export const ENDPOINT_RFQ_QUOTATION_ITEM_LIST                     = 'rfq/quotation_item_list'
export const ENDPOINT_RFQ_QUOTATION_ITEM_LIST_PAGINATION          = 'rfq/quotation_item_list/pagination'
export const ENDPOINT_RFQ_DELETE                                  = 'rfq/delete'
export const ENDPOINT_RFQ_LOG                                     = 'rfq/log'

export const ENDPOINT_RFQ_NEGOTIATION                             = 'rfq/negotiation'
export const ENDPOINT_RFQ_NEGOTIATION_REQUIRE                     = 'rfq/negotiation/schedule/require'  
export const ENDPOINT_RFQ_NEGOTIATION_SCHEDULE_CONFIRM            = 'rfq/negotiation/schedule/confirm'  
export const ENDPOINT_RFQ_NEGOTIATION_OFFER_UPLOAD                = 'rfq/negotiation/offer/upload'

export const ENDPOINT_RFQ_NEGOTIATION_OFFER_GET                   = 'rfq/negotiation/offer/get'  
export const ENDPOINT_RFQ_NEGOTIATION_OFFER_DELETE                = 'rfq/negotiation/offer/delete'
export const ENDPOINT_RFQ_NEGOTIATION_OFFER_LAST                  = 'rfq/negotiation/offer/last'
export const ENDPOINT_RFQ_NEGOTIATION_OFFER_SAVE                  = 'rfq/negotiation/offer/save'  
export const ENDPOINT_RFQ_NEGOTIATION_OFFER_DEAL                  = 'rfq/negotiation/offer/deal'  
export const ENDPOINT_RFQ_NEGOTIATION_OFFER_REVISION              = 'rfq/negotiation/offer/revision'
export const ENDPOINT_RFQ_NEGOTIATION_OFFER_REVISION_SEND_EMAIL   = 'rfq/negotiation/offer/revision/send-email'
export const ENDPOINT_RFQ_NEGOTIATION_OFFER_BAN                   = 'rfq/negotiation/offer/ban' 
export const ENDPOINT_RFQ_NEGOTIATION_BAN_APPROVE                 = 'rfq/negotiation/offer/ban/approve'

export const ENDPOINT_RFQ_NEGOTIATION_BAN_PREVIEW                 = 'rfq/negotiation/ban/preview'
export const ENDPOINT_RFQ_NEGOTIATION_BAN_REPORT                  = 'rfq/negotiation/ban/report'   
export const ENDPOINT_RFQ_NEGOTIATION_FPTN                        = 'rfq/negotiation/fptn'  
export const ENDPOINT_RFQ_NEGOTIATION_FPTN_APPROVE                = 'rfq/negotiation/fptn/approve'  
export const ENDPOINT_RFQ_NEGOTIATION_FPTN_REJECT                 = 'rfq/negotiation/fptn/reject' 
export const ENDPOINT_RFQ_NEGOTIATION_FPTN_PREVIEW                = 'rfq/negotiation/fptn/preview'
export const ENDPOINT_RFQ_NEGOTIATION_BAN_REJECT                  = 'rfq/negotiation/offer/ban/reject'
export const ENDPOINT_RFQ_NEGOTIATION_FPTN_REPORT                 = 'rfq/negotiation/fptn/report'  
export const ENDPOINT_RFQ_NEGOTIATION_FPTN_SUBMIT                 = 'rfq/negotiation/fptn/submit'  
export const ENDPOINT_RFQ_NEGOTIATION_RENEGOTIATION               = 'rfq/negotiation/renegotiation'  
export const ENDPOINT_RFQ_NEGOTIATION_SUMMARY_WITH                = 'rfq/negotiation/summary-with'
export const ENDPOINT_RFQ_NEGOTIATION_VERIFICATOR_CHANGE          = 'rfq/negotiation/verificator/change'  
export const ENDPOINT_RFQ_NEGOTIATION_BAN_REPORT_MENU             = 'rfq/negotiation/ban/report/menu'
export const ENDPOINT_RFQ_NEGOTIATION_BAN_REPORT_XLSX             = 'rfq/negotiation/ban/report/xlsx'
export const ENDPOINT_RFQ_NEGOTIATION_OFFER_DEAL_SUBMIT           = 'rfq/negotiation/offer/deal/submit'
export const ENDPOINT_RFQ_NEGOTIATION_FPTN_VERIFICATOR_CHANGE     = 'rfq/negotiation/fptn/verificator/change'

export const ENDPOINT_RFQ_NEGOTIATION_BAN_FPTN                    = 'rfq/negotiation/ban_fptn'
export const ENDPOINT_RFQ_NEGOTIATION_OFFER_APPROVER              = 'rfq/negotiation/offer/approver'

export const ENDPOINT_SEND_RFI                                    = 'masterdata/vendor/doc'
export const ENDPOINT_LIST_VENDORS                                = 'masterdata/vendor/rfi'
export const ENDPOINT_VENDOR_DETAILS_DOCUMENT_BY_VENDOR           = 'masterdata/vendor/rfi/detail/doc/vendor'
export const ENDPOINT_VENDOR_DETAILS_DOCUMENT_BY_PROJECT          = 'masterdata/vendor/rfi/detail/doc/project'
export const ENDPOINT_VENDOR_DETAILS_PROJECTS                     = 'masterdata/vendor/rfi/detail/project' 
export const ENDPOINT_VENDORS                                     = 'masterdata/vendor'
export const ENDPOINT_VENDORS_RFI_DETAIL_DOC_PROJECT_REF          = 'masterdata/vendor/rfi/detail/doc/project/ref'
export const ENDPOINT_VENDORS_RFI_DETAIL_DOC_VENDOR_UPLOAD        = 'masterdata/vendor/rfi/detail/doc/vendor/upload'
export const ENDPOINT_VENDORS_RFI_DETAIL_DOC_PROJECT_UPLOAD       = 'masterdata/vendor/rfi/detail/doc/project/upload'

export const ENDPOINT_KARYAWAN_NEGOTIATOR                         = 'masterdata/karyawan/negotiator'

export const ENDPOINT_FILE_UPLOAD                                 = 'file/upload'
export const ENDPOINT_FILE_DOWNLOAD                               = 'file/download'
export const ENDPOINT_FILE_DELETE                                 = 'file/delete'
