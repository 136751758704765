<template>
  <div v-if="false">
    <div class="w-full p-4">
      <div class="">
        <ButtonGista 
          color="secondary"
          @click="clickEdit"
          customClass="w-full">
          Edit SKT</ButtonGista>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditSktLoi",
  methods: {
    clickEdit() {
      console.log('clicked')
    }
  }
}

</script>