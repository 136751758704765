const RFQ = () => import("@/modules/module-rfq/pages/RFQ");
const QuotationDetails = () => import("@/modules/module-rfq/pages/QuotationDetails");
import { EDITRFQ, RFQNAME, DRAFTRFQ, QUOTATIONDETAILS, QUOTATIONNEGOTIATION,
	PATH_RFQ, PATH_DRAFT_RFQ, PATH_QUOTATION_DETAILS, PATH_QUOTATION_NEGOTIATION, PATH_EDIT_RFQ, 
} from '@/core/constant/routeName'

export const Routes = [
	{
		path: PATH_RFQ,
		name: RFQNAME,
		component: RFQ,
	},
  {
		path: PATH_DRAFT_RFQ,
		name: DRAFTRFQ,
		component: RFQ,
	},
	{
		path: PATH_QUOTATION_DETAILS,
		name: QUOTATIONDETAILS,
		component: QuotationDetails,
	},
  {
		path: PATH_QUOTATION_NEGOTIATION,
		name: QUOTATIONNEGOTIATION,
		component: QuotationDetails,
	},
	// {
  //   path: PATH_QUOTATION_APPROVAL,
  //   name: QUOTATIONAPPROVAL,
  //   component: QuotationDetails,
  // },
	{
		path: PATH_EDIT_RFQ,
		name: EDITRFQ,
		component: RFQ,
	},
];
