<template>
  <div v-if="support2">
		<div class="w-full p-4">
			<div class="">
				<ButtonGista type="secondary"
					color="primary"
					@click="handleOpen"
					customClass="w-full">
					Change Requester</ButtonGista>
			</div>
		</div>
		<GistaModal :value="isOpen"
			customClass="max-w-2xl bg-white"
			@input="handleClose">
			<ValidationObserver v-slot="{ handleSubmit }">
				<div class="w-full p-5">
					<div class="flex justify-center w-full mb-6">
						<h5 class="text-lg font-semibold text-gray">Change Requester</h5>
					</div>
					<div class="w-full gap-6 mb-6">
                        <InputDisabled label="Current Requester" class="mb-6" value="Sandy" />
						<ValidationProvider name="newRequester"
							rules="required"
							v-slot="{ errors }">
							<AutocompleteNewSolutip
								label="Change to:"
								placeholder="(Search)"
								:options="requesters"
								@updateInput="inputSearchRequester($event)"
								@updateAutoComplete="changeRequester($event)"
								@blur="blurRequester"
								@focus="focusRequester"
								v-model="newRequesterNameLocalState"
								:isLoading="isLoadingRequester"
								:error="!!errors[0]"
								textField="fullName"
								iconLeft="icon-search text-xl text-gray-light"
                                mandatory
							>
								<template #message>
									<p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
								</template>
							</AutocompleteNewSolutip>
						</ValidationProvider>
                        <ValidationProvider name="Reason" rules="required|max:300" v-slot="{errors}">
                            <TextareaGista
                                label="Reason"
                                placeholder="Leave a comment here"
                                v-model="reason"
                                :error="!!errors[0]"
                                class="mt-6"
                                mandatory
                            >
                                <template #message>
                                    <p>{{errors[0]}}</p>
                                </template>
                            </TextareaGista>
                        </ValidationProvider>
					</div>
					<div class="flex justify-end">
						<ButtonGista type="primary"
							color="success"
							@click="handleSubmit(clickChangeRequester)"
							customClass="w-full sm:w-56">
							Change
						</ButtonGista>

					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import { MESSAGE_SUCCESS_CHANGE_REQUESTER } from '@/core/constant/successMessage'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'

export default {
    name: "ApprovalChangeRequester",
    props: ["refetch"],
    data() {
        return {
            isOpen: false,
            support2: false,
            newRequesterNameLocalState: '',
            isLoadingRequester: false,
            form: {
                newRequesterName: '',
                newRequesterId: '',
            },
            reason: '',
            requesters: []
        }
    },
    computed: {
        menu() {
                return this.$store.state.dashboard.menu
            },
        modal: {
            get() {
            return this.$store.state.modal.modal
            },
            set(value) {
            this.$store.commit('modal/SET_MODAL', value)
            }
        },
    },
    methods: {
        async handleOpen() {
            this.isOpen = true
        },
        handleClose() {
            this.resetForm()
            this.isOpen = false
        },
        async inputSearchRequester(e) {
            this.isLoadingRequester = true
            this.requesters = [
            { fullName: 'Testing' },
            { fullName: 'Testing 1' },
            { fullName: 'Testing 2' },
            { fullName: 'Testing 3' },
            ]
            console.log(e)
            this.isLoadingRequester = false
        },
        changeRequester(e) {
            this.form.newRequesterId = e.userName
            this.form.newRequesterName = e.fullName
            this.newRequesterNameLocalState = e.fullName
        },
        blurRequester() {
            this.newRequesterNameLocalState = this.form.newRequesterName
        },
        async focusRequester() {
            this.isLoadingRequester = true
            this.requesters = [
            { fullName: 'Testing' },
            { fullName: 'Testing 1' },
            { fullName: 'Testing 2' },
            { fullName: 'Testing 3' },
            ]
            this.isLoadingRequester = false
        },
        resetForm() {
            this.form = {
            newRequesterName: '',
                    newRequesterId: '',
            }
            this.newRequesterNameLocalState = ''
        },
        async clickChangeRequester() {
            try {
            this.modal.modalLoading = true
            this.modal.modalLoading = false
            this.handleClose()
            this.resetForm()
            this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_CHANGE_REQUESTER }
            } catch (error) {
            console.log(error);
            this.modal.modalLoading = false
            this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
            }
        }
    },
    components: {
        AutocompleteNewSolutip,
        InputDisabled
    }
}
</script>