<template>
	<div class="flex w-full">
		<div class="relative flex w-full min-h-layout">
			<div :class="`${minimizeLog || windowWidth < 1024? 'w-full' : 'w-2/3 pr-6'}`">
				<div class="flex items-center w-full title my-11">
					<router-link :to="`/${PATH_ROOT}`">
					</router-link>
					<div @click="clickBackToProject">
						<p class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"></p>
					</div>
					<div class="flex flex-col items-center justify-start w-full gap-3 sm:justify-between sm:flex-row">
						<h4 class="pl-3 text-3xl font-medium">Quotation details</h4>
						<div>
							<p v-if="windowWidth < 1024" class="block underline cursor-pointer lg:hidden text-tertiary"
								@click="changeShowLog">Project Log</p>
							<div v-else class="flex items-center">
								<p v-if="minimizeLog" class="block pr-6 underline cursor-pointer text-tertiary"
									@click="changeShowLog">Project Log</p>
								<span v-if="windowWidth >= 1024 && minimizeLog" class="mr-3 text-lg cursor-pointer icon-maximize text-gray hover:text-gray-dark" @click=changeMinimizeLog></span>
							</div>
						</div>
					</div>
				</div>
				<div id="quotation-detail-container">
					<div class="w-full px-6 pb-6 mb-6 bg-white rounded-xl shadow-gray-sm">
						<div class="w-full border-b rounded-t-xl border-gray-lightest">
							<div class="flex justify-center w-full">
								<div class="flex overflow-x-auto">
									<router-link :to="`/${PATH_ROOT}/quotation-detail/${paramQuotationId}`">
										<h4 class="px-6 py-3 text-base text-center cursor-pointer"
											:class="{ 'border-b-2 border-primary text-gray': activePage === 'quotation-detail', 'text-gray-light hover:text-gray': activePage !== 'quotation-detail' }"
											>Details</h4>
									</router-link>
									<router-link :to="`/${PATH_ROOT}/quotation-negotiation/${paramQuotationId}`">
										<h4 class="px-6 py-3 text-base text-center cursor-pointer"
											:class="{ 'border-b-2 border-primary text-gray': activePage === 'quotation-negotiation', 'text-gray-light hover:text-gray': activePage !== 'quotation-negotiation' }"
											>Negotiation</h4>
									</router-link>
								</div>
							</div>
						</div>
						<slot></slot>
					</div>
				</div>
			</div>
			<div :class="{'hidden': !showLog && minimizeLog, 'w-full': minimizeLog || windowWidth < 1024, 'w-1/3': !minimizeLog && windowWidth >= 1024}">
				<div :class="{
					'bg-black-60 backdrop-filter backdrop-blur-sm h-full overflow-auto': showLog ,
					'sticky top-4 z-50': !showLog,
					'fixed left-0 top-0 z-50': showLog,
					'w-full': minimizeLog,
				}">
					<div
						class="flex justify-center mt-0"
						:class="{
							'small-h-log': windowHeight >= 800 && !minimizeLog,
							'large-h-log': windowHeight < 800,
							'h-full': showLog,
						}">
						<div 
							:class="{
								'w-full': windowWidth > 1024 && !minimizeLog,
								'w-11/12 my-6': showLog,
							}">
							<ProjectLogRfq :windowWidth="windowWidth" :showLog="showLog" :minimizeLog="minimizeLog"
								@click="changeShowLog" @clickMinimize="changeMinimizeLog"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ProjectLogRfq from '@/core/components/project-log/ProjectLogRfq.vue';
import { PATH_ROOT, QUOTATION_DETAILS, QUOTATION_NEGOTIATION } from '@/core/constant/routeName'

export default {
	name: "LayoutQuotationDetail",
	data() {
		return {
			showLog: false,
			minimizeLog: false, 
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
			PATH_ROOT,
		}
	},
	
	computed: {
		activePage: {
			get() {
				return this.$store.state.quotationDetail.activePage
			},
			set(value) {
				this.$store.commit('quotationDetail/SET_ACTIVE_PAGE', value)
			}
		},
		paramQuotationId: {
			get() {
				return this.$store.state.quotationDetail.paramQuotationId
			},
			set(value) {
				this.$store.commit('quotationDetail/SET_PARAM_QUOTATION_ID', value)
			}
		},
		quotationDetailContainerWidth: {
			get() {
				return this.$store.state.width.quotationDetailContainerWidth
			},
			set(value) {
				this.$store.commit('width/SET_QUOTATION_DETAIL_CONTAINER_WIDTH', value)
			}
		},
		filterProject: {
			get() {
				return this.$store.state.dashboard.filterProject
			},
			set(val) {
				this.$store.commit('dashboard/SET_FILTER_PROJECT', val)
			}
		},
	},
	watch: {
		showLog(value) {
			if (value) {
				return document.querySelector("body").classList.add("overflow-hidden");
			}

			document.querySelector("body").classList.remove("overflow-hidden");
		},
		windowWidth() {
			this.settingLogByWidth()
		},
	},
	methods: {
		changeShowLog() {
			this.showLog = !this.showLog
		},
		changeMinimizeLog() {
			this.minimizeLog = !this.minimizeLog
			setTimeout(() => {
				this.quotationDetailContainerWidth = document.getElementById('quotation-detail-container')?.offsetWidth
			}, 100)
		},
		settingLogByWidth() {
			if (this.windowWidth >= 1024) {
				this.showLog = false
			} else {
				this.minimizeLog = true
			}
		},
		switchActivePage() {
			const paramQuotationId = this.$route.params.quotationId
			this.paramQuotationId = paramQuotationId
			switch (this.$route.path) {
				case `/${PATH_ROOT}/${QUOTATION_DETAILS}/${paramQuotationId}`:
					this.activePage = QUOTATION_DETAILS
					break;
				case `/${PATH_ROOT}/${QUOTATION_NEGOTIATION}/${paramQuotationId}`:
					this.activePage = QUOTATION_NEGOTIATION
					break;
			}
		},
		async clickBackToProject() {
			const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
			this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
		},
		setWidthHeight() {
			this.quotationDetailContainerWidth = document.getElementById('quotation-detail-container')?.offsetWidth
			this.windowWidth = window.innerWidth
			this.windowHeight = window.innerHeight
		}
	},
	mounted() {
		this.quotationDetailContainerWidth = document.getElementById('quotation-detail-container')?.offsetWidth
		window.addEventListener('resize', () => this.setWidthHeight())
		this.settingLogByWidth()
		this.switchActivePage()
	},
	beforeUpdate() {
		this.switchActivePage()
	},
	destroyed() {
		window.removeEventListener('resize', () => this.setWidthHeight)
	},
	components: { ProjectLogRfq }
}
</script>

<style scoped>
.large-h-log {
	height: calc(100vh - 10rem);
}
.small-h-log {
	height: 40rem;
}
.min-h-layout {
	min-height: 42rem;
}
</style>