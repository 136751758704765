<template>
	<div class="flex w-full">
		<div class="relative flex w-full min-h-layout">
			<div :class="`${minimizeLog || windowWidth < 1024? 'w-full' : 'w-2/3 pr-6'}`">
				<div class="flex items-center w-full title my-11">
					<div @click="clickBackToProject">
						<p class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"></p>
					</div>
					<div class="flex flex-col items-center justify-start w-full gap-3 sm:justify-between sm:flex-row">

						<h4 class="pl-3 text-3xl font-medium">Project details</h4>
						<div>
							<p v-if="windowWidth < 1024" class="block underline cursor-pointer lg:hidden text-tertiary"
								@click="changeShowLog">Project Log</p>
							<div v-else class="flex items-center">
								<p v-if="minimizeLog" class="block pr-6 underline cursor-pointer text-tertiary"
									@click="changeShowLog">Project Log</p>
								<span v-if="windowWidth >= 1024 && minimizeLog" class="mr-3 text-lg cursor-pointer icon-maximize text-gray hover:text-gray-dark" @click=changeMinimizeLog></span>
							</div>
						</div>
					</div>
				</div>
				<div id="project-detail-container">
					<div class="w-full px-6 pb-6 mb-6 bg-white rounded-xl shadow-gray-sm">
						<div class="w-full border-b rounded-t-xl border-gray-lightest">
							<div class="flex justify-center w-full">
								<div class="flex overflow-x-auto">
									<div>
										<router-link :to="`/${PATH_ROOT}/${PROJECT_DETAIL}/${paramProjectId}`">
											<h4 class="px-6 py-3 text-base text-center cursor-pointer"
												:class="{ 'border-b-2 border-primary text-gray': activePage === PROJECT_DETAIL, 'text-gray-light hover:text-gray': activePage !== PROJECT_DETAIL }"
												>Details</h4>
										</router-link>
									</div>
									<div>
										<router-link :to="`/${PATH_ROOT}/${PROJECT_PRA_QUALIFICATION}/${paramProjectId}`">
											<h4 v-if="roleUserITP && pradetailShow"
												title="Pra-Qualification"
												class="px-6 py-3 text-base text-center cursor-pointer w-max"
												:class="{ 'border-b-2 border-primary text-gray': activePage === PROJECT_PRA_QUALIFICATION, 'text-gray-light hover:text-gray': activePage !== PROJECT_PRA_QUALIFICATION }"
												>Pra-Qualification</h4>
										</router-link>
									</div>
									<div>
										<router-link :to="`/${PATH_ROOT}/${PROJECT_QUALIFICATION}/${paramProjectId}`">
											<h4 v-if="pradetailShow"
												class="px-6 py-3 text-base text-center cursor-pointer"
												:class="{ 'border-b-2 border-primary text-gray': activePage === PROJECT_QUALIFICATION, 'text-gray-light hover:text-gray': activePage !== PROJECT_QUALIFICATION }"
												>Qualification</h4>
										</router-link>
									</div>
									<div>
										<router-link :to="`/${PATH_ROOT}/${PROJECT_SCORING}/${paramProjectId}`">
											<h4 v-if="pradetailShow && noSingleVendor"
												class="px-6 py-3 text-base text-center cursor-pointer"
												:class="{ 'border-b-2 border-primary text-gray': activePage === PROJECT_SCORING, 'text-gray-light hover:text-gray': activePage !== PROJECT_SCORING }"
												>Scoring</h4>
											</router-link>
									</div>
									<div>
										<router-link :to="`/${PATH_ROOT}/${PROJECT_NEGOTIATION}/${paramProjectId}`">
											<h4  v-if="pradetailShow"
												class="px-6 py-3 text-base text-center cursor-pointer"
												:class="{ 'border-b-2 border-primary text-gray': activePage === PROJECT_NEGOTIATION, 'text-gray-light hover:text-gray': activePage !== PROJECT_NEGOTIATION }"
												>Negotiation</h4>
										</router-link>
									</div>
								</div>
							</div>
						</div>
						<slot :windowWidth="windowWidth"></slot>
					</div>
				</div>
			</div>
			<div :class="{'hidden': !showLog && minimizeLog, 'w-full': minimizeLog || windowWidth < 1024, 'w-1/3': !minimizeLog && windowWidth >= 1024}">
				<div :class="{
					'bg-black-60 backdrop-filter backdrop-blur-sm h-full overflow-auto': showLog ,
					'sticky top-4 z-50': !showLog,
					'fixed left-0 top-0 z-50': showLog,
					'w-full': minimizeLog,
				}">
					<div
						class="flex justify-center mt-0"
						:class="{
							'small-h-log': windowHeight >= 800 && !minimizeLog,
							'large-h-log': windowHeight < 800 && !showLog,
							'h-full': showLog,
						}">
						<div
							:class="{
								'w-full': windowWidth > 1024 && !minimizeLog,
								'w-11/12 my-6': showLog,
							}">
							<ProjectLog :windowWidth="windowWidth" :showLog="showLog" :minimizeLog="minimizeLog"
								@click="changeShowLog" @clickMinimize="changeMinimizeLog" />
	
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import ProjectLog from '@/core/components/project-log/ProjectLog.vue';
import { PATH_ROOT, PROJECT_DETAIL, PROJECT_PRA_QUALIFICATION, PROJECT_QUALIFICATION, PROJECT_SCORING, PROJECT_NEGOTIATION } from '@/core/constant/routeName'
import { VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT, COMPLETED, CANCELED } from '@/core/constant/statusProject'
import { QUALIFICATION_ELIMINATED, PRA_QUALIFICATION_ELIMINATED } from '@/core/constant/qualification.js'

export default {
	name: "LayoutProjectDetail",
	data() {
		return {
			showLog: false,
			minimizeLog: false, 
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
			timeout: null,
			PROJECT_DETAIL,
			PROJECT_PRA_QUALIFICATION,
			PROJECT_QUALIFICATION,
			PROJECT_SCORING,
			PROJECT_NEGOTIATION,
			PATH_ROOT,
		}
	},
	computed: {
		pradetailShow() {
			const statusProject = [VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT, COMPLETED, CANCELED]
			return this.projectLogRfp?.rfpLogs.some(e => statusProject.includes(e.statusProject))
		},
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		vendorCandidate() {
			return this.$store.state.projectDetail.vendorCandidate
		},
		qualifications() {
			return this.$store.state.qualification.qualifications
		},
		qualificationActive() {
			const statusVendor = [ QUALIFICATION_ELIMINATED, PRA_QUALIFICATION_ELIMINATED ]
			return this.qualifications.filter(e => !statusVendor.includes(e.statusVendor))
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		noSingleVendor() {
			return this.qualificationActive.length > 1 || this.isSoftware
		},
		isSoftware() {
			return this.proposalInformation.isSoftware
		},
		activePage: {
			get() {
				return this.$store.state.projectDetail.activePage
			},
			set(value) {
				this.$store.commit('projectDetail/SET_ACTIVE_PAGE', value)
			}
		},
		paramProjectId: {
			get() {
				return this.$store.state.projectDetail.paramProjectId
			},
			set(value) {
				this.$store.commit('projectDetail/SET_PARAM_PROJECTID', value)
			}
		},
		projectDetailContainerWidth: {
			get() {
				return this.$store.state.width.projectDetailContainerWidth
			},
			set(value) {
				this.$store.commit('width/SET_PROJECT_DETAIL_CONTAINER_WIDTH', value)
			}
		},
		filterProject: {
			get() {
				return this.$store.state.dashboard.filterProject
			},
			set(val) {
				this.$store.commit('dashboard/SET_FILTER_PROJECT', val)
			}
		},
		menuDashboard() {
			return this.$store.getters['dashboard/menuDashboard']
		},
		roleUserITP() {
			//only opr itp
			return this.menuQualificationActionSendRfp?.canCreate
		},
		menuQualificationActionSendRfp() {
			return this.$store.getters['dashboard/menuQualificationActionSendRfp']
		},
	},
	watch: {
		showLog(value) {
			if (value) {
				return document.querySelector("body").classList.add("overflow-hidden");
			}

			document.querySelector("body").classList.remove("overflow-hidden");
		},
		windowWidth() {
			this.settingLogByWidth()
		},
	},
	methods: {
		changeShowLog() {
			this.showLog = !this.showLog
		},
		changeMinimizeLog() {
			this.minimizeLog = !this.minimizeLog
			setTimeout(() => {
				this.projectDetailContainerWidth = document.getElementById('project-detail-container')?.offsetWidth
			}, 100)
		},
		settingLogByWidth() {
			if (this.windowWidth >= 1024) {
				this.showLog = false
			} else {
				this.minimizeLog = true
			}
		},
		switchActivePage() {
			const paramProjectId = this.$route.params.projectId
			this.paramProjectId = paramProjectId
			switch (this.$route.path) {
				case `/${PATH_ROOT}/${PROJECT_DETAIL}/${paramProjectId}`:
					this.activePage = PROJECT_DETAIL
					break;
				case `/${PATH_ROOT}/${PROJECT_PRA_QUALIFICATION}/${paramProjectId}`:
					this.activePage = PROJECT_PRA_QUALIFICATION
					break;
				case `/${PATH_ROOT}/${PROJECT_QUALIFICATION}/${paramProjectId}`:
					this.activePage = PROJECT_QUALIFICATION
					break;
				case `/${PATH_ROOT}/${PROJECT_SCORING}/${paramProjectId}`:
					this.activePage = PROJECT_SCORING
					break;
				case `/${PATH_ROOT}/${PROJECT_NEGOTIATION}/${paramProjectId}`:
					this.activePage = PROJECT_NEGOTIATION
					break;
			}
		},
		async clickBackToProject() {
			const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
			this.$store.dispatch('projectDetail/resetApproverScoringDecision')
			this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
		},
		setWidthHeight() {
			this.projectDetailContainerWidth = document.getElementById('project-detail-container')?.offsetWidth
			this.windowWidth = window.innerWidth;
			this.windowHeight = window.innerHeight;
		}
	},
	async mounted() {
		this.projectDetailContainerWidth = document.getElementById('project-detail-container')?.offsetWidth
		window.addEventListener('resize', () => this.setWidthHeight())
		this.settingLogByWidth()
		this.switchActivePage()
	},
	beforeUpdate() {
		this.switchActivePage()
	},
	destroyed() {
		document.querySelector("body").classList.remove("overflow-hidden");
		window.removeEventListener('resize', () => this.setWidthHeight())
	},
	components: {
		ProjectLog,
	}
}
</script>

<style scoped>
.large-h-log {
	height: calc(100vh - 10rem);
}
.small-h-log {
	height: 40rem;
}
.min-h-layout {
	min-height: 42rem;
}
</style>