<template>
  <div v-if="false">
    <div class="w-full p-4">
      <div class="">
        <ButtonGista 
          color="secondary"
          @click="clickEditApproval"
          customClass="w-full">
          Edit</ButtonGista>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditProcurementApproval",
  methods: {
    clickEditApproval() {
      console.log('clicked')
    }
  }
}

</script>