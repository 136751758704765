<template>
	<div class="w-full">
		<div class="w-full" :class="`${rejectStatus || isUserCanCancel?'p-4':''}`">
	
			<div class="flex justify-between w-full" v-if="isUserCanCancel">
				<div :class="`${rejectStatus? 'w-1/2 mr-4': 'w-full'}`">
					<ButtonGista type="secondary"
						color="error"
						:isDark="false"
						@click="toggle"
						customClass="w-full">
						Cancel Project
					</ButtonGista>
				</div>
				<div :class="`${rejectStatus && !isUserCanCancel? 'w-full':'w-1/2'}`" v-if="rejectStatus">
					<ButtonGista 
						type="secondary"
						color="success"
						@click="editProject"
						class="w-full">
						Edit Project</ButtonGista>
				</div>
			</div>
		</div>
		<GistaModal :value="isOpen"
			customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver v-slot="{ handleSubmit }">
				<div class="w-full p-5">
					<div class="flex justify-center w-full">
						<h5 class="text-lg font-semibold text-gray">Batalkan RFP</h5>
					</div>
					<div class="flex-wrap w-full mb-4">
						<ValidationProvider name="Reason"
							rules="required|max:300"
							v-slot="{ errors }">
							<TextareaGista label="Reason"
								placeholder="Leave a comment here"
								v-model="reason"
								:error="!!errors[0]"
								mandatory>
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>

					</div>
					<div class="flex justify-center">
						<ButtonGista type="primary"
							color="error"
							@click="handleSubmit(clickCancel)"
							customClass="w-full sm:w-56">
							Cancel
						</ButtonGista>

					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
		
		<gista-modal :value="readMoreShow"
			customClass="max-w-2xl bg-white"
			:scrollable="true"
			@input="toggleShow"
		>
			<div class="w-full p-6">
				<div class="flex p-3 pr-10">
					<p class="text-sm font-medium break-words whitespace-pre-wrap text-gray">{{projectLogRfp.rejectReason}}</p>
				</div>
			</div>
		</gista-modal>
	</div>
</template>
<script>
import { PATH_ROOT } from '@/core/constant/routeName'
import { GSIT_01_AWAITING_REVISION, UKKP_01_AWAITING_REVISION } from '@/core/constant/statusProject'
import { MESSAGE_SUCCESS_CANCEL_RFP} from '@/core/constant/successMessage'

export default {
	name: "CancelEditRfp",
	data() {
		return {
			isOpen: false,
			readMoreShow: false,
			reason: ''
		}
	},
	computed: {
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		userProfile() {
			return this.$store.state.dashboard.userProfile
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		rejectStatus() {
			const status = [ GSIT_01_AWAITING_REVISION, UKKP_01_AWAITING_REVISION ]
			const rfpLogs = this.projectLogRfp?.rfpLogs
			if (rfpLogs.length > 0) {
				return status.includes(rfpLogs[rfpLogs.length - 1]?.statusProject)
			} else {
				return false
			}
		},
		isUserCanCancel() {
			return this.userProfile.username === this.proposalInformation.technicalCoordinatorId
		},
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
	},
	methods: {
		toggle() {
			this.isOpen = !this.isOpen
			this.reason = ''
		},
		async clickCancel() {
			this.isOpen = false
			try {
				this.modal.modalLoading = true
				const response = await this.$store.dispatch('approval/putProposalInformationCancel', { rfpId: this.$route.params.projectId, reason: this.reason })
				console.log(response, 'error response reject');
				if (response?.status < 300 && response?.status >= 200) {
					this.reason = ''
					this.modal.modalLoading = false
					this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_CANCEL_RFP }
					const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
					this.$store.commit('projectLog/SET_PROJECT_ID', this.proposalInformation.projectId)
					this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
					this.$emit('refetchProjectList')
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				console.log(error);
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		editProject() {
			this.$router.push(`/${PATH_ROOT}/edit-rfp/${this.$route.params.projectId}`).catch(() => ({}))
		},
		toggleShow() {
			this.readMoreShow = !this.readMoreShow
		}
	},
}
</script>