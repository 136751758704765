<template>
	<div class="w-full" v-if="false">
		<div class="w-full p-4">
      <p class="mb-1 text-sm text-gray">Procurement Approval from Approver</p>
      <p class="mb-6 text-sm text-gray-dark">Upload signed approval</p>
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          type="secondary"
          color="error"
          :isDark="false"
          @click="toggle"
          customClass="w-full">
          Reject RFP
        </ButtonGista>
        <ButtonGista 
          type="primary"
          color="success"
          @click="toggleUpload"
          class="w-full">
          Upload Approval</ButtonGista>
      </div>
		</div>
		<GistaModal 
      :value="isOpen"
      customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Tolak Procurement Approval</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:300" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-56">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
		<GistaModal 
      :value="isOpen2"
      customClass="max-w-2xl bg-white"
			@input="toggleUpload">
      <ValidationObserver ref="updateApprover">
				<div class="w-full p-5">
					<div class="flex justify-center w-full mb-4">
						<h5 class="text-lg font-semibold text-gray">Upload Signed Approval</h5>
					</div>
					<div class="mb-4">
						<p class="mb-1 text-sm text-gray">Approval Document <span class="text-sm text-error">*</span></p>
						<div class="flex items-center justify-between w-full border rounded-lg"
							:class="`${errorFileType ? 'border-error' : 'border-primary'}`">
							<input type="file"
								id="upload-file-update-approver"
								ref="file"
								hidden
								@change="chooseFile($event)" />
							<div class="flex justify-between" style="width: calc(100% - 7rem);">
								<span class="px-4 text-sm truncate text-gray" :title="form.files?.name">{{ form.files?.name }}</span>
								<span v-if="form.files?.name" class="pr-2 cursor-pointer icon-trash-2 text-error hover:text-error-dark" @click="removeFile"></span>
							</div>
							<label for="upload-file-update-approver"
								class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
								Pilih File
							</label>
						</div>
						<div>
							<p class="mt-1 text-xs text-tertiary"
								:class="{ 'text-error': errorFileType }">
								Supported file: .pdf, max file size: 10 MB
							</p>
						</div>
					</div>
          <div class="flex flex-col justify-between gap-6 py-6 mt-6 border-t-2 lg:flex-row border-gray-lightest" >
            <div class="flex items-center">
              <p class="mr-3 text-xl icon-alert-circle text-gray"></p>
              <p class="mr-3 font-medium text-gray">Approval bersifat mutlak dan tidak dapat diubah setelah diunggah</p>
            </div>
            <div class="flex justify-end">
              <ButtonGista type="primary"
                color="success"
                @click="clickUploadApproval"
                customClass="w-full sm:w-56">
                Upload & Approve
              </ButtonGista>
            </div>
          </div>
					<div class="flex justify-end">

					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import { MESSAGE_SUCCESS_UPLOAD_APPROVAL, MESSAGE_SUCCESS_REJECT_APPROVAL } from '@/core/constant/successMessage'
export default {
	name: "UploadApprovalReject",
	data() {
		return {
			isOpen: false,
			isOpen2: false,
      reason: '',
      form: {
				files: {},
			},
			errorFileType: false,

		}
	},
  computed: {
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
    proposalInformation() {
      return this.$store.state.projectDetail.proposalInformation
    },
  },
	methods: {
    async clickReject() {
      this.isOpen = false
      try {
				this.modal.modalLoading = true
				this.reason = ''
				this.modal.modalLoading = false
				this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_REJECT_APPROVAL }
			} catch (error) {
				console.log(error);
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
    },
    async chooseFile(e) {
			const file = e.target.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
			if(checkFileValidation) {
				if(!this.form?.files) this.errorFileType = true
			} else {
				this.form.files = file
				this.errorFileType = false
			}
			if (this.$refs['file']) {
				this.$refs['file'].value = ''
			}
		},
    removeFile() {
			if (this.$refs['file']) {
				this.$refs['file'].value = ''
			}
			this.form.files = {}
		},
    checkErrorValidate() {
			if (!this.form.files?.name) {
				this.errorFileType = true
			}
			return !this.errorFileType
		},
    async clickUploadApproval() {
			try {
				this.modal.modalLoading = true
				const checkErrorValidate = this.checkErrorValidate()
				if (!checkErrorValidate) {
					this.modal.modalLoading = false
					return
				}
				//hash file checksum md5
				this.modal.modalLoading = false
				this.isOpen2 = !this.isOpen2
				this.form =  {
					files: {},
				}
				this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_UPLOAD_APPROVAL }
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		toggle() {
			this.isOpen = !this.isOpen
      this.reason = ''
		},
		toggleUpload() {
			this.isOpen2 = !this.isOpen2
		},
	},
}
</script>

<style scoped>
.width-button-submit {
  max-width: 40rem;
}
</style>