<template>
  <div v-if="false && !editRequester">
    <div class="w-full p-4">
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          type="secondary"
          color="error"
          @click="toggleReject"
          customClass="w-full">
          Cancel Approval</ButtonGista>
        <ButtonGista 
          type="secondary"
          color="primary"
          @click="handleEditRequester"
          customClass="w-full">
          Change Approvers</ButtonGista>
      </div>
    </div>
    <GistaModal 
      :value="isOpenReject"
      customClass="max-w-2xl bg-white"
			@input="toggleReject">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Batalkan Approval</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:300" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-60">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
  </div>
</template>

<script>

export default {
  name: 'ApprovalChangeApprover',
  data() {
    return {
      isOpen: false,
      isOpenReject: false,
      reason: '',
      recommendation: [],
      selectedRecommendation: [
        [{name: 'Testing 1', disabled: true}, {name: ''}],
        [{name: 'Testing 1'}, {name: ''}],
        [{name: 'Testing 2'}, {name: ''}]
      ],
      fields: [
        {name: 'Recommendation'},
        {name: 'Checker', optional: true },
        {name: 'Approver',},
      ]
    };
  },
  computed: {
    editRequester() {
      return this.$store.state.approval.editRequester
    },
  },
  methods: {
    handleSubmit() {
      console.log('clicked')
    },
    handleEditRequester() {
      this.$store.dispatch('procurementApproval/changeApprovals', true)
    },
    toggleIsOpen() {
      this.isOpen = !this.isOpen
    },
    toggleReject() {
      this.isOpenReject = !this.isOpenReject
    },
    focusRecommendation() {
      this.recommendation = [
        { fullName: 'Testing' },
        { fullName: 'Testing 1' },
        { fullName: 'Testing 2' },
        { fullName: 'Testing 3' },
      ]
    },
    changeRecommendation(e, i, j) {
      this.selectedRecommendation[i][j] = {name: e.fullName}
    },
    inputSearch(e) {
      console.log('search', e)
    },
    clickReject() {
      console.log('clickReject')
    }
  }
};
</script>