<template>
  <div v-if="false">
    <div class="w-full p-4">
      <div class="">
        <ButtonGista 
          type="secondary"
          color="primary"
          @click="toggleIsOpen"
          customClass="w-full">
          Change Approval</ButtonGista>
      </div>
    </div>
    <GistaModal :value="isOpen"
      customClass="max-w-2xl bg-white"
      @input="toggleIsOpen">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full mb-4">
            <h5 class="text-lg font-semibold text-gray">Change Approval</h5>
          </div>
          <div class="w-full mb-4" v-for="n, i in fields" :key="i">
            <ValidationProvider name="changeApproval"
              rules="required"
              v-slot="{ errors }">
              <AutocompleteNewSolutip
                :label="`Approval ${i + 1}`"
                placeholder="(Search)"
                :options="approvals"
                @updateInput="inputSearch($event)"
                @updateAutoComplete="changeApproval($event, i)"
                @focus="focusRecommendation"
                v-model="selectedApproval[i]"
                :isLoading="isLoading"
                :error="!!errors[0]"
                textField="name"
                iconLeft="icon-search text-xl text-gray-light"
              >
                <template #message>
                  <p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
                </template>
              </AutocompleteNewSolutip>
            </ValidationProvider>
          </div>
          <div class="flex justify-end">
            <ButtonGista type="primary"
              color="success"
              @click="handleSubmit(submitChange)"
              customClass="w-full sm:w-56">
              Change Approval
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
    </GistaModal>
  </div>
</template>

<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'

export default {
  name: 'ApprovalChangeApprover',
  data() {
    return {
      isOpen: false,
      approvals: [],
      selectedApproval: [

      ],
      fields: [],
      isLoading: false
    };
  },
  mounted() {
    this.fields = ['', '']
  },
  methods: {
    handleSubmit() {
      console.log('clicked handleSubmit')
    },
    toggleIsOpen() {
      this.isOpen = !this.isOpen
    },
    focusRecommendation() {
      this.approvals = [
        { fullName: 'Testing' },
        { fullName: 'Testing 1' },
        { fullName: 'Testing 2' },
        { fullName: 'Testing 3' },
      ]
    },
    changeApproval(e, i) {
      this.selectedApproval[i] = {name: e.fullName}
    },
    inputSearch(e) {
      console.log('search', e)
    },
    submitChange() {
      console.log('clicked submitChange')
    }
  },
  components: {
    AutocompleteNewSolutip
  }
};
</script>