/* eslint-disable */
import { ENDPOINT_QUALIFICATION, ENDPOINT_QUALIFICATION_ACTION_SEND_RFP, ENDPOINT_QUALIFICATION_ACTION_DISQUALIFIED,
  ENDPOINT_QUALIFICATION_ACTION_REVISION, ENDPOINT_QUALIFICATION_ACTION_APPROVE, ENDPOINT_QUALIFICATION_ACTION_SEND_PROPOSAL,
  ENDPOINT_QUALIFICATION_ACTION_ASK_REVISION, ENDPOINT_SEND_RFI } from '@/core/constant/menuEndpoint'
export const Store = {
  namespaced: true,
  name: 'qualification',
  state: {
    qualifications: [],
    isEnableSendProposal: null,
    rfpId: null,
    qualificationsRevision: {},
    qualificationsRevisionTech: {},
    totalApprovedVendorProposal: null,
  },
  gettters: {

  },
  mutations: {
    SET_QUALIFICATIONS(state, payload) {
      state.qualifications = payload
    },
    SET_IS_ENABLE_SEND_PROPOSAL(state, payload) {
      state.isEnableSendProposal = payload
    },
    SET_RFP_ID(state, payload) {
      state.rfpId = payload
    },
    SET_TOTAL_APPROVED_VENDOR_PROPOSAL(state, payload) {
      state.totalApprovedVendorProposal = payload
    },
    SET_QUALIFICATIONS_REVISION(state, payload) {
      state.qualificationsRevision = payload
    },
    SET_QUALIFICATIONS_REVISION_TECH(state, payload) {
      state.qualificationsRevisionTech = payload
    }
  },
  actions: {
    //Api Get
    //Get Qualification Documents, Proposal, Eliminated Vendor
    async getQualifications({ commit, state }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_QUALIFICATION}/${rfpId}`)
        console.log(response, 'response get qualifications');
        const result = response.data.result
        const dataCommitQualification = result.vendors?.map(e => {
          return {
            ...e,
            errorFileTechnical: { value: false, message: '' },
            errorFilePrice: { value: false, message: '' },
            localPartners: e.localPartners?.map(el => {
              return {
                ...el,
                errorFileTechnical: { value: false, message: '' },
                errorFilePrice: { value: false, message: '' },
              }
            })
          }
        })
        commit('SET_QUALIFICATIONS', dataCommitQualification)
        commit('SET_IS_ENABLE_SEND_PROPOSAL', result.isEnableSendProposal)
        commit('SET_RFP_ID', result.rfpId)
        commit('SET_TOTAL_APPROVED_VENDOR_PROPOSAL', result.totalApprovedVendorProposal)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //Get qualification prooposal revision vendor candidate
    async getQualificationsProposalRevision({ commit, state, }, payload) {
      try {
        const vendorCandidateId = payload
        const response = await this.$api.get(`${ENDPOINT_QUALIFICATION_ACTION_ASK_REVISION}/${vendorCandidateId}`)
        console.log(response, 'get qualification proposal revision vendor candidate');
        commit('SET_QUALIFICATIONS_REVISION', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //Get qualification proposal revision tech vendor candidate
    async getQualificationsProposalRevisionTech({ commit, state, }, payload) {
      try {
        const vendorCandidateId = payload
        const response = await this.$api.get(`${ENDPOINT_QUALIFICATION_ACTION_REVISION}/${vendorCandidateId}`)
        console.log(response, 'get qualification proposal revision tech vendor candidate');
        commit('SET_QUALIFICATIONS_REVISION_TECH', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //POST
    //Post qualifications send document pre
    async postQualificationsSendDoc({ commit, state}, payload) {
      try {
        const dataPost = payload
        console.log(payload, 'payload');
        const response = await this.$api.post(ENDPOINT_SEND_RFI, dataPost)
        console.log(response, 'response post send doc pre');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //Post qualifications send rfp
    async postQualificationsSendRfp({ commit, state, }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.post(`${ENDPOINT_QUALIFICATION_ACTION_SEND_RFP}/${rfpId}`)
        console.log(response, 'post qualification send rfp');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    async postProposalSend({ commit, state, }, payload) {
      try {
        const projectId = payload
        const response = await this.$api.post(`${ENDPOINT_QUALIFICATION_ACTION_SEND_PROPOSAL}/${projectId}`)
        console.log(response, 'response post proposal send');
        return response;
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //PUT
    //Put qualification proposal approval
    async putQualificationProposalApproval({ commit, state,}, payload) {
      try {
        const { vendorCandidateId, isPerluPoc } = payload
        const response = await this.$api.put(ENDPOINT_QUALIFICATION_ACTION_APPROVE, { vendorCandidateId, isPerluPoc })
        console.log(response, 'response put qualification proposal approval');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //Put qualification eliminate vendor
    async putQualificationEliminateVendor({ commit, state, }, payload) {
      try {
        //formData = {rfpId, vendorId, reason, file}
        const formData = payload
        const response = await this.$api.put(ENDPOINT_QUALIFICATION_ACTION_DISQUALIFIED, formData)
        console.log(response, 'put qualification eliminate vendor');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //Put qualification proposal revision
    async putQualificationsProposalRevision({ commit, state, }, payload) {
      try {
        const { vendorCandidateId, revisionReason } = payload
        const response = await this.$api.put(ENDPOINT_QUALIFICATION_ACTION_ASK_REVISION, { vendorCandidateId, revisionReason })
        console.log(response, 'put qualification proposal revision');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //Put qualification proposal revision tech
    async putQualificationsProposalRevisionTech({ commit, state, }, payload) {
      try {
        const { vendorCandidateId, revisionMessage } = payload
        const response = await this.$api.put(ENDPOINT_QUALIFICATION_ACTION_REVISION, { vendorCandidateId, revisionMessage })
        console.log(response, 'put qualificatioin proposal revision tech');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    }
  }
  
}