/* eslint-disable */
import { ENDPOINT_REPORT_CHART, ENDPOINT_REPORT_DOWNLOAD } from "@/core/constant/menuEndpoint";
export const Store = {
	namespaced: true,

	// nama module, untuk call di component menggunakan "example/<nama_method>"
	name: 'report',

	state: {
    chartDataRFP: [],
    chartDataRFQ: [],
    chartDataRFX: [],
    chartDataRFI: [],
	},

	getters: {},

	mutations: {
    SET_CHART_DATA_RFP(state, payload) {
      state.chartDataRFP = payload
    },
    SET_CHART_DATA_RFQ(state, payload) {
      state.chartDataRFQ = payload
    },
    SET_CHART_DATA_RFX(state, payload) {
      state.chartDataRFX = payload
    },
    SET_CHART_DATA_RFI(state, payload) {
      state.chartDataRFI = payload
    },
	},

	actions: {
    async getReportChart({commit, state}, payload) {
      try {
        const { startYear, endYear, moduleName} = payload
        const response = await this.$api.get(ENDPOINT_REPORT_CHART, { startYear, endYear, moduleName } )

        console.log(response, 'get report chart');
        switch(moduleName) {
          case 'rfp':
            commit('SET_CHART_DATA_RFP', response.data.result);
            break;
          case 'rfq':
            commit('SET_CHART_DATA_RFQ', response.data.result);
            break;
          case 'rfx':
            commit('SET_CHART_DATA_RFX', response.data.result);
            break;
          case 'rfi':
            commit('SET_CHART_DATA_RFI', response.data.result);
            break;
        }
        return response
      } catch (error) {
        console.log(error);
      }
    },
    async getDownloadReport({ commit, state,}, payload) {
      try {
        const { reportName, start, end } = payload
        const response = await this.$api.get(ENDPOINT_REPORT_DOWNLOAD, { reportName, start, end }, {responseType:'blob'})
        console.log(response, 'get download report');
        const blob = new Blob([response.data])
        const link = window.URL.createObjectURL(blob)
        const fileLink = document.createElement('a');
        fileLink.href = link;
        fileLink.setAttribute('download', `${reportName} ${start} - ${end}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click()
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    }
	}
}