/* eslint-disable */

import CryptoJS from 'crypto-js'
import { ENDPOINT_FILE_UPLOAD, ENDPOINT_FILE_DOWNLOAD, ENDPOINT_FILE_DELETE } from '@/core/constant/menuEndpoint'
import { MESSAGE_FILE_TIDAK_DISUPPORT, MESSAGE_FILE_SIZE } from '@/core/constant/errorMessage'
import { FILE_EXTENSION } from '@/core/constant/regex.js'

export const Store = {
  namespaced: true,
  name: 'file',
  state: {

  },
  gettters: {

  },
  mutations: {
  },
  actions: {
    hashMD5({commit, state, dispatch}, payload) {
      const file = payload
			return new Promise((resolve, reject) => {
				let reader = new FileReader()
				reader.onload = (event) => {
					let data = event.target.result
          let hash = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(data));
          let md5 = hash.toString(CryptoJS.enc.Hex)
					resolve(md5)

				}
				reader.onerror = reject
				reader.readAsBinaryString(file);
			})
		},
    getFileExtension({ commit, state }, payload) {
      const { filename } = payload
      const extension = filename.match(FILE_EXTENSION);
      if (extension) {
        return extension[0].substring(1);
      }
      return '';
    },
    async fileReaderOpenNewTab({ commit, state, dispatch }, payload) {
      const response = payload
      const filename = response.headers['x-customheader-filename']
      const fileExtension = await dispatch('getFileExtension', { filename })
      const defaultType = {
        get: (obj, property) => property in obj ? obj[property] : 'image/jpeg'
      }
      const typeDict = new Proxy(
        {
          pdf: "application/pdf",
          png: "image/png",
        },
        defaultType
      )
      const fileType = typeDict[fileExtension]
      const blob = new Blob([response.data], { type: fileType })
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    },
    async uploadFile({ commit }, payload) {
      try {
        console.log(payload, 'file')
        const response = await this.$api.post(ENDPOINT_FILE_UPLOAD, payload)
        console.log(response);
        return response
      } catch (error) {
        console.log(error.response, 'error upload file store');
        return error.response
      }
    },
    async checkFileValidation({ commit, state, dispatch, rootState }, payload) {
      const { file, fileType } = payload
      const fileSize = payload.fileSize || 10*1024*1024
      const fileSizeString = payload.fileSizeString || '10 MB'
      const modal = rootState.modal.modal
      if (!file || !fileType.includes(file.type)) {
        commit('modal/SET_MODAL', { ...modal, modalErrorValidate: { value: true, message: MESSAGE_FILE_TIDAK_DISUPPORT } }, { root: true })
        return true
      }
      //handle image/jpeg only jpeg and jpg
      const filename = file.name
      const fileExtension = await dispatch('getFileExtension', { filename })
      const jpegExtension = ['jpg', 'jpeg']
      if (file.type === 'image/jpeg' && !jpegExtension.includes(fileExtension)) {
        commit('modal/SET_MODAL', { ...modal, modalErrorValidate: { value: true, message: MESSAGE_FILE_TIDAK_DISUPPORT } }, { root: true })
        return true
      }
      if (!file || file.size > fileSize ) {
        commit('modal/SET_MODAL', { ...modal, modalErrorValidate: { value: true, message: `${MESSAGE_FILE_SIZE} ${fileSizeString}` } }, { root: true })
        return true
      }
      return false
    },
    async viewAttachment({ commit, state, dispatch, rootState }, payload) {
      const modal = rootState.modal.modal
      const { filename, documentNo } = payload
      console.log(filename, documentNo, 'view attachment payload');
      try {
				modal.modalLoading = true
				let response = null
				if (documentNo) {
					response = await dispatch('downloadFileGet', { documentNo })
				} else {
					response = await dispatch('downloadFilePost', { filename })
				}
				if (response?.status < 300 && response?.status >= 200) {
          dispatch('fileReaderOpenNewTab', response)
          modal.modalLoading = false
        } else {
          dispatch('modal/throwError', { response }, { root: true })
        }
			} catch (error) {
				modal.modalLoading = false
				modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }				
			}
    },
    async downloadFileGet({ commit, state, }, payload) {
      try {
        const { documentNo } = payload
        const response = await this.$api.get(`${ENDPOINT_FILE_DOWNLOAD}/${documentNo}`, null, { responseType: 'blob' })
        console.log(response, 'response download get');
        return response
      } catch (error) {
        console.log(error.response, 'error download file get store');
        return error.response
      }
    },
    async downloadFilePost({ commit, state, }, payload) {
      try {
        const { filename } = payload
        const response = await this.$api.post(ENDPOINT_FILE_DOWNLOAD, { filename }, { responseType: 'blob' })
        console.log(response, 'response download post');
        return response
      } catch (error) {
        console.log(error.response, 'error download file post store');
        return error.response
      }
    },
    async deleteFile({ commit }, payload) {
      try {
        console.log(payload, 'documentNo');
        const documentNo = payload
        const response = await this.$api.delete(`${ENDPOINT_FILE_DELETE}/${documentNo}`)
        console.log(response, 'response delete file');
        return response
      } catch (error) {
        console.log(error.response, 'error delete file');
        return error.response
      }
    }
  }
  
}