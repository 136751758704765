const Home = () =>
	import(
    /* webpackChunkName: "Example" */ "@/modules/module-dashboard/pages/Home"
	);
import { PATH_ROOT } from '@/core/constant/routeName'

export const Routes = [
	{
		path: `/${PATH_ROOT}`,
		name: "Home",
		component: Home,
	},
  {
		path: `/${PATH_ROOT}/vendors`,
		name: "Vendors",
		component: Home,
	},
];
